
  
  .tools-panel {
    padding: 10px;
    border: 1px solid salmon !important;
    border-radius: 4px;
    background-color: #f9f9f9;
    width: 250px;
  }
  
  .tools-panel p {
    text-align: left;
  }
  
  .checkbox-container {
    margin-bottom: 8px;
    border-bottom: 1px solid salmon;
  }
  .checkbox-option {
    margin-bottom: 8px;
  }
  
  .checkbox-container label {
    display: flex;
    align-items: center;
    font-size: 14px;
   
  }
  
  .checkbox-container input[type="checkbox"] {
    margin-right: 8px;
   
  }
  
  .upload-container {
    margin-top: 10px;
  }
  
  .upload-container input[type="file"] {
    display: block;
    margin-top: 10px;
  }
  
  .upload-container input[type="text"] {
    margin-top: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .list-class{
    display: flex;
    align-items: center;
  }
  .list-input{
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: left;
    padding: 5px;
  }
  .close-icon{
    margin-top: 10px;
    border: none;
    background: none;
    cursor: pointer;
  }
  .preview-tab{
    border: 2px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    width: 100vh;
    overflow: auto;
  }
  .export-btn{
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  .variable-input-container{
    display: flex;
    flex-direction: column;
    padding: 0%;
    border-bottom: 1px solid salmon;
    padding-bottom: 20px;
  }
  .close-btn{
    background: none;
    border: none;
    cursor: pointer;
  }

  /* Container for the template name input */
.template-name-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

/* Label for the template name input */
.template-name-container label {
  font-size: 16px;
  font-weight: 600;
  margin-right: 8px;
}

/* Template name input field */
.template-name-container input {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
}