nav {
    display: flex;
    padding: 5px 20px 5px 10px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .nav-class {
    position: sticky;
    top: 0; /* Position it at the top of the page */
    left: 0; /* Align it to the left */
    right: 0; /* Align it to the right */
    z-index: 10;
    background-color: white;
  }
  
  .icon {
    height: 3vh;
    cursor: pointer;
  }
  
  .menu-button {
    border-radius: 50% !important;
  }
  .menu-button:active,
  .menu-button:hover {
    background: none !important;
  }
  .options-list {
    display: flex;
    font-size: 10px;
    justify-content: space-around !important;
    align-items: center;
    margin-left: 10px !important;
  }
  .options-text {
    font-weight: 600;
    margin-left: 10px !important;
  }
  
  .user-details-option {
    display: flex;
    align-items: "center";
    justify-content: space-around !important;
  }
  
  .survey-icon {
    height: "20px";
    width: "20px";
  }
  
  .dashboard-icon {
    font-size: 20px;
  }
