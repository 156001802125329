/* src/App.css */

.App {
  text-align: center;
    overflow-x: hidden;
    overflow-y: hidden;
}

textarea {
  font-family: monospace;
  font-size: 16px;
  width: 100%;
  height: 100%;
}

main {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid #ccc;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 10px;
  cursor: pointer;
  margin-right: 5px;
}

.react-tabs__tab--selected {
  background: white;
  border-color: #ccc #ccc #fff;
  color: black;
}

.react-tabs__tab-panel {
  padding: 10px;
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  width: 100%;
}


.preview-app{
  overflow-x: hidden;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
